<template>
  <v-card class="pa-4" flat>
    <Header
      :idClass="idClass"
      :count="table.dataTable.length"
      @setIdClass="newValue => (idClass = newValue)"
      @search="newValue => (table.search = newValue)"
    />
    <Table :table="table" :idClass="idClass" />
  </v-card>
</template>

<script>
import { getListEtest } from "@/api/admin/academic/eTest";

export default {
  metaInfo: {
    title: "E-Test",
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Header: () => import("./Header"),
    Table: () => import("./Table")
  },
  watch: {
    idClass(newValue) {
      this.listEtest(newValue);
    },
    "table.search"(newValue) {
      this.table.search = newValue;
      this.listEtest(this.idClass);
    }
  },
  data() {
    return {
      idClass: 0,
      table: {
        dataTable: [],
        loading: false,
        search: ""
      }
    };
  },
  methods: {
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    async listEtest(idClass) {
      this.table.loading = true;
      this.$Progress.start();
      try {
        const response = await getListEtest({
          class: idClass,
          search: this.table.search
        });
        if (response.data.code === 1) {
          this.table.dataTable = response.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.table.loading = false;
      this.$Progress.finish();
    }
  }
};
</script>
